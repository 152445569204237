import React from "react";
import ImportLinks from "../other/ImportLinks";
import Heading2 from "../other/Heading2";
import PageHeaderImg from "../other/PageHeaderImg";
import { Link } from "react-router-dom";
import Footer from '../other/Footer';
import FooterPre from '../other/FooterPre';

const Budget = () => {
  const TableItems = [
    {
      id: 1,
      itemNameA: "Budget 2018-19",
      itemNameB: "Original",
      url: "../Footer/NagarNigamBudget/2018-19/Original/2018-19O.pdf",
      itemNameC: "View",
    },
    {
      id: 2,
      itemNameA: "Budget 2018-19",
      itemNameB: "Revised",
      url: "../Footer/NagarNigamBudget/2018-19/Revised/2018-19R.pdf",
      itemNameC: "View",
    },
    {
      id: 3,
      itemNameA: "Budget 2019-20",
      itemNameB: "Original",
      url: "../Footer/NagarNigamBudget/2019-20/Original/2019-20O.pdf",
      itemNameC: "View",
    },
    {
      id: 4,
      itemNameA: "Budget 2019-20",
      itemNameB: "Revised",
      url: "../Footer/NagarNigamBudget/2019-20/Revised/2019-20R.pdf",
      itemNameC: "View",
    },
    {
      id: 5,
      itemNameA: "Budget 2020-21",
      itemNameB: "Original",
      url: "../Footer/NagarNigamBudget/2020-21/Original/2020-21O.pdf",
      itemNameC: "View",
    },
    {
      id: 6,
      itemNameA: "Budget 2020-21",
      itemNameB: "Revised",
      url: "../Footer/NagarNigamBudget/2020-21/Revised/2020-21O.pdf",
      itemNameC: "View",
    },
    {
      id: 7,
      itemNameA: "Budget 2021-22",
      itemNameB: "Original",  // Fixed typo from itemNamB to itemNameB
      url: "../Footer/NagarNigamBudget/2021-22/Original/2021-22O.pdf",
      itemNameC: "View",
    },
    {
      id: 8,
      itemNameA: "Budget 2021-22",
      itemNameB: "Revised",
      url: "../Footer/NagarNigamBudget/2021-22/Revised/2021-22R.pdf",
      itemNameC: "View",
    },
    {
      id: 9,
      itemNameA: "Budget 2022-23",
      itemNameB: "Original",
      url: "../Footer/NagarNigamBudget/2022-23/Original/2022-23O.pdf",
      itemNameC: "View",
    },
    {
      id: 10,
      itemNameA: "Budget 2022-23",
      itemNameB: "Revised",
      url: "../Footer/NagarNigamBudget/2022-23/Revised/2022-23R.pdf",
      itemNameC: "View",
    },
    {
      id: 11,
      itemNameA: "Budget 2023-24",
      itemNameB: "Original",
      url: "../Footer/NagarNigamBudget/2023-24/Original/2023-24O.pdf",
      itemNameC: "View",
    },
    {
      id: 12,
      itemNameA: "Budget 2023-24",
      itemNameB: "Revised",
      url: "../Footer/NagarNigamBudget/2023-24/Revised/2023-24R.pdf",
      itemNameC: "View",
    },
    {
      id: 13,
      itemNameA: "Budget 2024-25",
      itemNameB: "Original",
      url: "../Footer/NagarNigamBudget/2024-25/Original/2024-25O.pdf",
      itemNameC: "View",
    },
    {
      id: 14,
      itemNameA: "Budget 2024-25",
      itemNameB: "Revised",
      url: "../Footer/NagarNigamBudget/2024-25/Revised/2024-25R.pdf",
      itemNameC: "View",
    },
  ];
  
  return (
    <>
      <div className="container-fluid accountBGImg">
        <div className=" container breadcum">
          <Heading2 HName="Budget" />
          <PageHeaderImg
            BreadName1="Home"
            BreadName2="Budget"
          />
        </div>
      </div>
      <div className="container-fluid ">
        <div className="row py-4">
          <div className="col-lg-9">
            <div className="card mb-3">
              <div className="card-header h4 bg-warning">
                Budget
              </div>
              <div className="card-body aboutus d-flex justify-content-center">
                <table className="table theadebg table-hover table-striped table-bordered">
                  <thead>
                    <tr>
                      <th scope="col">S.No</th>
                      <th scope="col">Subject</th>
                      <th scope="col">Category</th>
                      <th scope="col">Download</th>
                    </tr>
                  </thead>
                  <tbody>
                    {TableItems.map((e) => (
                      <tr key={e.id}>
                        <th scope="row">{e.id}</th>
                        <td>
                          {e.itemNameA}
                       
                        </td>
                        <td>
                          {e.itemNameB}
                       
                        </td>
                        <td>
                          <a href={e.url} className="btn btn-warning btn-sm" target="_blank" rel="noopener noreferrer">
                              {e.itemNameC}
                            </a>
                        </td>
                      </tr>
                      
                    ))}
                  </tbody>
                </table>
              </div>
              </div>

          </div>
          <div className="col-lg-3">
            <ImportLinks />
          </div>
        </div>
        <Footer />
      <FooterPre/>
      </div>
    </>
  );
};

export default Budget;
