import React from 'react'
import ImportLinks from './../../other/ImportLinks';
import PageHeaderImg from './../../other/PageHeaderImg';
import Heading2 from './../../other/Heading2';
import Footer from '../../other/Footer';
import FooterPre from '../../other/FooterPre';

const NewsLetter = () => {
    const TableItems = [
      {
        id: 1,
        itemName: "February Newsletter in English",
        itemName1: "View",
        url: "../Footer/Feb_English_Newletter.pdf",
      },
      {
        id: 2,
        itemName: "February Newsletter in Hindi",
        itemName1: "View",
        url: "../Footer/Feb_Hindi_Newsletter.pdf",
      },
      {
        id: 3,
        itemName: "January Newsletter in English",
        itemName1: "View",
        url: "../Footer/january_English_2025.pdf",
      },
      {
        id: 4,
        itemName: "January Newsletter in Hindi",
        itemName1: "View",
        url: "../Footer/january_hindi_2025.pdf",
      },
      {
        id: 5,
        itemName: "December Newsletter in Hindi",
        itemName1: "View",
        url: "../Footer/December_eNewsletter_Hindi.pdf",
      },
      {
        id: 6,
        itemName: "December Newsletter in English",
        itemName1: "View",
        url: "../Footer/December_eNewsletter_Eng.pdf",
      },
      {
        id: 7,
        itemName: "November Newsletter in Hindi",
        itemName1: "View",
        url: "../Footer/November_eNewsletter_Hindi.pdf",
      },
      {
        id: 8,
        itemName: "November Newsletter in English",
        itemName1: "View",
        url: "../Footer/November_eNewsletter_English.pdf",
      },
      {
        id: 9,
        itemName: "October Newsletter in Hindi",
        itemName1: "View",
        url: "../Footer/October_eNewsletter_Hindi.pdf",
      },
      {
        id: 10,
        itemName: "October Newsletter in English",
        itemName1: "View",
        url: "../Footer/October_eNewsletter_English.pdf",
      },
      {
        id: 11,
        itemName: "September Newsletter in Hindi",
        itemName1: "View",
        url: "../Footer/September_eNewsletter_Hindi.pdf",
      },
      {
        id: 12,
        itemName: "September Newsletter in English",
        itemName1: "View",
        url: "../Footer/September_eNewsletter_English.pdf",
      },
      {
        id: 13,
        itemName: "August Newsletter in Hindi",
        itemName1: "View",
        url: "../Footer/August_eNewsletter_Hindi.pdf",
      },
      {
        id: 14,
        itemName: "August Newsletter in English",
        itemName1: "View",
        url: "../Footer/August_eNewsletter_English.pdf",
      },
      {
        id: 15,
        itemName: "July Newsletter in Hindi",
        itemName1: "View",
        url: "../Footer/July_eNewsletter_Hindi.pdf",
      },
      {
        id: 16,
        itemName: "July Newsletter in English",
        itemName1: "View",
        url: "../Footer/July_eNewsletter_English.pdf",
      },
      {
        id: 17,
        itemName: "June Newsletter in Hindi",
        itemName1: "View",
        url: "../Footer/e-newsletter.pdf",
      },
      {
        id: 18,
        itemName: "June Newsletter in English",
        itemName1: "View",
        url: "../Footer/Nagar_Nigam_English_Newsletter.pdf",
      },
      
    ];
  
    return (
      <>
        <div className="container-fluid accountBGImg">
          <div className=" container breadcum">
            <Heading2 HName="NewsLetter" />
            <PageHeaderImg BreadName1="Home" BreadName2="NewsLetter" />
          </div>
        </div>
        <div className="container-fluid">
          <div className="row py-4">
            <div className="col-lg-9">
              <div className="card mb-3">
                <div className="card-header h4 bg-warning">NewsLetter</div>
                <div className="card-body aboutus d-flex justify-content-center">
                  <table className="table theadebg table-hover table-striped table-bordered">
                    <thead>
                      <tr>
                        <th scope="col">S.No</th>
                        <th scope="col">Subject</th>
                        <th scope="col">Download</th>
                      </tr>
                    </thead>
                    <tbody>
                      {TableItems.map((e) => (
                        <tr key={e.id}>
                          <th scope="row">{e.id}</th>
                          <td>
                            {e.itemName}
                            <br />
                            <small>
                              <i className="fa-regular fa-file-pdf"></i> 
                              {/* <b>Size:</b> 164 KB | <b>Lang:</b> Hindi | */}
                              {/* <b>Uploading Date:</b> 10/05/21 */}
                            </small>
                          </td>
                          <td>
                            <a href={e.url} className="btn btn-warning btn-sm" target="_blank" rel="noopener noreferrer">
                              {e.itemName1}
                            </a>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <ImportLinks />
            </div>
          </div>
        </div>
        <Footer />
        <FooterPre />
      </>
    );
  };
  
  export default NewsLetter;
  